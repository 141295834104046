import { useState } from 'react';
import axios from 'axios';

const NewsLetter = () => {

  const initialState = {
    email: ""
  }

  const [state, setState] = useState(initialState)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)

  const closeAlert = () => {
    setSuccess("")
    setError("")
    setIsSubmit(false)
  }

  const onChange = (field, event) => {
    closeAlert()

    const updatedState = { ...state }

    updatedState[field] = event.target.value

    setState(updatedState)
  }

  const onSubmit = async () => {
    closeAlert()
    const payload = {
      email: state.email
    }

    const errors = []

    if (payload.email === '') {
      errors.push('Email can not be empty.')
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (payload.email !== '' && !emailRegex.test(payload.email)) {
      errors.push('Email is not valid.')
    }

    if (errors.length > 0) {
      const newError = errors.map((e) => `<li>${e}</li>`)
      setError(`<ul class="text-left">${newError}</ul>`)
    } else {
      setIsSubmit(true)
      try {
        const { data } = await axios.post('/scripts/NewsLetter.php', payload)
        closeAlert()
        if (data.status === 'Error') {
          setError(data.message)
        } else {
          setSuccess(data.message)
        }
        setTimeout(() => {
          closeAlert()
        }, 5000);
      } catch (e) {
        closeAlert()
        setError(`Something went wrong. Please try again later.`)
        setTimeout(() => {
          closeAlert()
        }, 5000);
      }
    }

  }

  return (
    <section id="newsletter" className="small-section bg-color1">
      <div className="container text-center text-light">
        <div className="col-md-6">
          <h4>Subscribe to our Newsletter</h4>
        </div>
        <div className="col-md-6">
          <div className="input-group">
            <input className="form-control input-lg" type="email" placeholder="Your email here" value={state.email} onChange={(event) => onChange('email', event)} />
            <span className="input-group-btn">
              <button className="btn btn-primary btn-outline" onClick={() => onSubmit()}>Subscribe</button>
            </span>
          </div>
          {isSubmit ? <div className="alert alert-info response">Subscribing... Please wait!</div> : null}
          {error !== '' ? <div className="alert alert-danger response" dangerouslySetInnerHTML={{ __html: error }}></div> : null}
          {success !== '' ? <div className="alert alert-success response text-black white-bg" dangerouslySetInnerHTML={{ __html: success }}></div> : null}

        </div>

      </div>

    </section>
  )
}

export default NewsLetter