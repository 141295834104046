import girl from '../assets/images/teams/nurture-team-girl.png'
import team1 from '../assets/img/Nidhi1.jpg'

// import OwlCarousel from 'react-owl-carousel';
const Teams = () => {
  return (
    <section id="team" className="container-fluid bg-color4">

      <div className="parallax-obj1 hidden-sm hidden-xs team-para" data-0="opacity:1;"
        data-100="transform:translatey(40%);"
        data-center-center="transform:translatey(-60%);">

        <img src={girl} alt="toddler education programs" />
      </div>

      <div className="section-heading">
        <h2>Owner and Director</h2>

        <div className="hr"></div>
      </div>
      <div className="container">

        {/* <OwlCarousel className='owl-theme' dots loop nav margin={50} responsive={{
          1: {
            items: 1,
          }
        }} > */}
        <div className="team-member">
          <div className="pic">

            <img src={team1} className="img-responsive img-circle" alt="Nidhi Gupta" />

            <div className="social">
              <ul className="social-icon">
                <li><a href="https://www.facebook.com/nidhiayushi" target="_blank" rel="noreferrer"><span className="fa fa-facebook"></span></a></li>
                <li><a href="https://instagram.com/nidhixgupta" target="_blank" rel="noreferrer"><span className="fa fa-instagram"></span></a></li>
              </ul>
            </div>

            <div className="team-info">
              <h4>Nidhi Gupta</h4>
              {/* <span className="post">Owner</span> */}
            </div>

          </div>

          <p className="text-left description text-black">
            Hi, I'm Nidhi Gupta, a happily married mother of two daughters. One is a computer science freshman at Berkeley, and the other attends TESLA STEM High School in Redmond, WA. Prior to owning Nurture with Care Kids Academy, I was a para-educator for over 8 years, split between Lake Washington School District (LWSD) in WA and Fremont Unified School District (FUSD) in CA, working with students from pre-K to 6th grade. I supervised and led groups of 6 to 10 students in various subjects, including PE and computer labs. In my free time, I enjoy shopping, cooking, and family time. I'm excited for our journey at Nurture with Care Kids Academy, aiming to make a bigger impact on our community.
          </p>
        </div>

        {/* </OwlCarousel> */}


      </div>

    </section>
  )
}

export default Teams