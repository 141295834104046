
import React from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop';
const Career = () => {

  return (
    <>
      <ScrollToTop />
      <div id="page-section">
        <Layout title="Childcare Professional Staffing | Nurture with Care Kids Academy" description="Nurture with Care Kids Academy is seeking teachers for childcare center in Oak Harbor for Young Toddlers, Toddlers, and Preschool classrooms. Apply now for childcare professional positions.">
          <Helmet>
            <meta property="og:site_name" content="Nurture With Care Kids Academy" />
            <meta property="og:url" content="https://nurturewithcarekidsacademy.com/career" />
            <meta property="og:title" content="Career" />
            <meta property="og:type" content="article" />
            <meta property="og:description" content="Join our Oak Harbor daycare team! Explore fulfilling career opportunities in nurturing environments. Make a positive impact on young lives. Apply today!" />
            <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/logo-nurture.fe8ff81585c61f3e8f0c.png" />

          </Helmet>
          <section id="blog" style={{ paddingTop: "0" }}>

            <div className="container-fluid">

              <div className="jumbo-heading">
                <h1>Career</h1>
              </div>

              <div className="container">
                <div id="blog-container" className="col-md-12">
                  <div className="blog-post post-main">

                    <p>Please find the open positions below:</p>
                    <ol>
                      <li>
                        <p><strong>Paid Internship</strong></p>
                        <p><strong>Center Operating Hours:</strong> 7 AM - 6 PM</p>
                        <p><strong>Your Working Hours:</strong> Up to 8 hours per day</p>
                        <p><strong>Salary:</strong> Hourly pay $16.28</p>
                        <p><strong>Location:</strong> 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>

                        <p>
                          <strong>Position Summary:</strong>
                        </p>
                        As an intern at Nurture with Care Kids Academy, you'll have the opportunity to:
                        <ul>
                          <li><p>Assist experienced teachers in implementing engaging and age-appropriate activities for children.</p></li>
                          <li><p>Support children's social, emotional, cognitive, and physical development through play-based learning.</p></li>
                          <li><p>Create a positive and inclusive environment where every child feels valued and supported.</p></li>
                          <li><p>Collaborate with our team to ensure the safety and well-being of all children in our care.</p></li>
                        </ul>

                        <p>
                          <strong>Why Choose Us:</strong>
                        </p>
                        <ul>
                          <li><p>Gain hands-on experience in early childhood education under the guidance of professionals.</p></li>
                          <li><p>Develop essential skills such as communication, problem-solving, and teamwork in a supportive and dynamic environment.</p></li>
                          <li><p>Make a difference in the lives of children and families by contributing to their growth and development.</p></li>
                          <li><p>Opportunity for growth and advancement within our organization for outstanding interns.</p></li>
                        </ul>
                        <p>
                          <strong>How to Apply:</strong>
                        </p>
                        <ul>
                          <p> If you're ready to embark on a rewarding journey with Nurture with Care Kids Academy, we encourage you to apply for our internship program today! Simply send your resume and a brief cover letter expressing your interest in the internship opportunity.</p>
                        </ul>

                        <p><a style={{ marginTop: "0" }} href="mailto:admin@nurturewithcarekidsacademy.com?subject=Application for Internship: Please Attach Resume" className="btn btn-primary">Apply Now</a></p>
                      </li>

                      <br />

                      <li>
                        <p><strong>Assistant Director/Program Supervisor</strong></p>
                        <p><strong>Center Operating Hours:</strong> 7 AM - 6 PM</p>
                        <p><strong>Your Working Hours:</strong> Up to 8 paid hours per day</p>
                        <p><strong>Salary:</strong> Hourly pay range $20.00 - $30.00</p>
                        <p><strong>Location:</strong> 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>
                        <p><strong>Benefits:</strong></p>
                        <ul>
                          <li><strong>Pre-tax 401(k)</strong> Contribution and Employer Match, Conditions apply.</li>
                          <li><strong>Quarterly Performance Bonus.</strong></li>
                          <li><strong>Longevity Bonus:</strong> A $1,000 bonus upon completing one year and 2,000 service hours with us.</li>
                          <li><strong>Referral Bonus:</strong> You will receive a $1,000 referral bonus for each person you refer who is hired and remains employed with us for six months.</li>
                          <li><strong>Child discount:</strong> 15% off one child's enrollment at our center and 10% off a multi-child discount for the other children.</li>
                          <li><strong>Free Tuition and books for Early Childhood Education (ECE) Courses:</strong> This benefit is after completing 90 days of employment with us.</li>
                          <li><strong>Sick Pay:</strong> 1 hour of sick pay for every 40 hours of paid work.</li>
                          <li><strong>Future Expansion and Career Advancement:</strong> Opportunity to become program supervisor and director as we expand, and you have the required qualifications.</li>
                          <li><strong>Lunch/Coffee:</strong> Provided free of cost every Friday.</li>
                          <li><strong>Pay Increase After Enrollment in ECE courses:</strong> After enrolling in an Early Childhood Education (ECE) program at an accredited school (e.g., Skagit Valley) and completing the first course, you will receive a $1 per hour pay increase.</li>
                          <li><strong>Initial ECE Certificate (12 course credits):</strong> Upon completing the initial ECE certificate, you will receive an additional $1 per hour pay increase.</li>
                          <li><strong>Short ECE Certificate (additional 8 course credits):</strong> You will receive an additional $1 per hour pay increase upon completing the ECE short certificate.</li>
                          <li><strong>State ECE Certificate (additional 27 course credits):</strong> Upon completing the ECE state certificate, you will receive an additional $2 per hour pay increase.</li>
                        </ul>

                        <p>
                          <strong>Position Summary:</strong>
                        </p>
                      
                        <ul>
                          <li>
                          An Assistant Director/Program Supervisor is responsible for ensuring compliance, overseeing curriculum development, managing staff, ensuring child safety, fostering community relations, upholding legal compliance, and driving continuous improvement in a nurturing childcare environment.
                          </li>
                          <li>
                          The pre-service and hiring requirements for an Assistant Director/Program Supervisor staff include a background check, TB test, minimum age of 18, and possession of a WA ECE State Certificate (or equivalent) to be completed or in progress with a completion target by August 2026. Alternatively, an AAS or BA degree in education can be considered. The role may require the person to spend some time in the classrooms as a lead teacher.
                          </li>
                        </ul>
                        <p><a style={{ marginTop: "10px" }} href="mailto:admin@nurturewithcarekidsacademy.com?subject=Application for Assistant Director, or Program Supervisor: Please Attach Resume" className="btn btn-primary">Apply Now</a></p>
                      </li>

                      <br></br>


                      <li>
                        <p><strong>Toddler/Preschool Teachers (ages 12 months – 5 years)</strong></p>
                        <p><strong>Center Operating Hours:</strong> 7 AM - 6 PM</p>
                        <p><strong>Your Working Hours:</strong> Up to 8 paid hours per day</p>
                        <p><strong>Salary:</strong> Hourly pay range $17.50 - $20.00</p>
                        <p><strong>Location:</strong> 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>
                        <p><strong>Other Benefits:</strong></p>
                        <ul>
                          <li><strong>Pre-tax 401(k)</strong> Contribution and Employer Match, Conditions apply.</li>
                          <li><strong>Quarterly Performance Bonus.</strong></li>
                          <li><strong>Longevity Bonus:</strong> A $1,000 bonus upon completing one year and 2,000 service hours with us.</li>
                          <li><strong>Referral Bonus:</strong> You will receive a $1,000 referral bonus for each person you refer who is hired and remains employed with us for six months.</li>
                          <li><strong>Child discount:</strong> 15% off one child's enrollment at our center and 10% off a multi-child discount for the other children.</li>
                          <li><strong>Free Tuition and books for Early Childhood Education (ECE) Courses:</strong> This benefit is after completing 90 days of employment with us.</li>
                          <li><strong>Sick Pay:</strong> 1 hour of sick pay for every 40 hours of paid work.</li>
                          <li><strong>Future Expansion and Career Advancement:</strong> Opportunity to become program supervisor and director as we expand, and you have the required qualifications.</li>
                          <li><strong>Lunch/Coffee:</strong> Provided free of cost every Friday.</li>
                          <li><strong>Pay Increase After Enrollment in ECE courses:</strong> After enrolling in an Early Childhood Education (ECE) program at an accredited school (e.g., Skagit Valley) and completing the first course, you will receive a $1 per hour pay increase.</li>
                          <li><strong>Initial ECE Certificate (12 course credits):</strong> Upon completing the initial ECE certificate, you will receive an additional $1 per hour pay increase.</li>
                          <li><strong>Short ECE Certificate (additional 8 course credits):</strong> Upon completing the ECE short certificate, you will receive an additional $1 per hour pay increase.</li>
                          <li><strong>State ECE Certificate (additional 27 course credits):</strong> You will receive an additional $2 per hour pay increase upon completing the ECE state certificate.</li>
                        </ul>
                        <p>
                          <strong>Position Summary:</strong>
                        </p>
                        A teacher is responsible for implementing the center's early learning program. Teachers develop and provide a nurturing and responsive learning environment that meets the needs of enrolled children. A teacher must meet the following qualifications:
                        <ul>
                          <li>Be at least eighteen years old</li>
                          <li>Have a high school diploma or equivalent.</li>
                          <li>Complete the applicable preservice requirements: DCYF background check and Negative TB test report.</li>
                          <li>Prior experience is preferred; however, we can consider the right candidate if this is their first job.</li>
                        </ul>
                        <p><a href="mailto:admin@nurturewithcarekidsacademy.com?subject=Application for Lead Young Toddler Teacher: Please Attach Resume" style={{ marginTop: "1rem" }} className="btn btn-primary">Apply Now</a></p>
                      </li>

                      <br></br>

                      {/* <li>
                        <p><strong>Lead Toddler Teacher (ages 20 - 35 months)</strong></p>
                        <p><strong>Center Operating Hours:</strong> 7 AM - 6 PM</p>
                        <p><strong>Your Working Hours:</strong> 4 to 8 hours per day during the center's operating hours</p>
                        <p><strong>Salary:</strong> Hourly pay range $17.50 - $20.00</p>
                        <p><strong>Location:</strong> 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>
                        <p><strong>Other Benefits:</strong> </p>
                        <ul>
                          <li>Pre-tax 401(K) with employer match.</li>
                          <li>$500 incentive plus an 8-hour time-off award after successfully completing 6 months.</li>
                          <li>$750 incentive plus an 8-hour time-off award after successfully completing 12 months.</li>
                          <li>15% off one child's enrollment in the Child Development Center and 10% off a multi-child discount for the other children.</li>
                          <li>Refer a new hire and receive a $300 award after the referral completes their first 6 months and remains employed with us.</li>
                        </ul>

                        <p>
                          <strong>Position Summary:</strong>
                        </p>
                        A Lead is responsible for implementing the center's early learning program. Lead teachers develop and provide a nurturing and responsive learning environment that meets the needs of enrolled children. A lead teacher must meet the following qualifications:
                        <ul>
                          <li>Be at least eighteen years old.</li>
                          <li>Have a high school diploma or equivalent.</li>
                          <li>Complete the applicable preservice requirements: DCYF background check, 30 hours of orientation training, and Negative TB test report.</li>
                          <li>Have an ECE initial certificate or equivalent or enrolled in an ECE certification program.</li>
                          <li>Prior experience preferred; however, we can consider the right candidate with this is their first job.</li>
                        </ul>
                        <p><a href="mailto:admin@nurturewithcarekidsacademy.com?subject=Application for Lead Toddler Teacher: Please Attach Resume" style={{ marginTop: "1rem" }} className="btn btn-primary">Apply Now</a></p>

                      </li>

                      <br></br>

                      <li>
                        <p><strong>Lead Preschool Teacher (ages 3 - 6 years)</strong></p>
                        <p><strong>Center Operating Hours:</strong> 7 AM - 6 PM</p>
                        <p><strong>Your Working Hours:</strong> 4 to 8 hours per day during the center's operating hours</p>
                        <p><strong>Salary:</strong> Hourly pay range $17.50 - $20.00</p>
                        <p><strong>Location:</strong> 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>
                        <p><strong>Other Benefits:</strong></p>
                        <ul>
                          <li>Pre-tax 401(K) with employer match.</li>
                          <li>$500 incentive plus an 8-hour time-off award after successfully completing 6 months.</li>
                          <li>$750 incentive plus an 8-hour time-off award after successfully completing 12 months.</li>
                          <li>15% off one child's enrollment in the Child Development Center and 10% off a multi-child discount for the other children.</li>
                          <li>Refer a new hire and receive a $300 award after the referral completes their first 6 months and remains employed with us.</li>
                        </ul>

                        <p>
                          <strong>Position Summary:</strong>
                        </p>
                        A Lead is responsible for implementing the center's early learning program. Lead teachers develop and provide a nurturing and responsive learning environment that meets the needs of enrolled children. A lead teacher must meet the following qualifications:
                        <ul>
                          <li>Be at least eighteen years old.</li>
                          <li>Have a high school diploma or equivalent.</li>
                          <li>Complete the applicable preservice requirements: DCYF background check, 30 hours of orientation training, and Negative TB test report.</li>
                          <li>Have an ECE initial certificate or equivalent or enrolled in an ECE certification program.</li>
                          <li>Prior experience preferred; however, we can consider the right candidate with this is their first job.</li>
                        </ul>
                        <p><a href="mailto:admin@nurturewithcarekidsacademy.com?subject=Application for Lead Preschool Teacher: Please Attach Resume" style={{ marginTop: "1rem" }} className="btn btn-primary">Apply Now</a></p>

                      </li> */}
                      <br></br>

                    </ol>
                    <br></br>
                    <p>You can also send your resume to <a href="mailto:admin@nurturewithcarekidsacademy.com" className="btn-link">admin@nurturewithcarekidsacademy.com</a>. Make sure you add the position title in the subject line. </p>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </Layout>
      </div>
    </>

  )
}

export default Career