import yt from '../assets/images/services/young-toddlers-program1.jpg'
import yt2 from '../assets/images/services/young-program2.jpg'
import young from '../assets/images/services/individualized-care.svg'
import safe from '../assets/images/services/safe-exploration.svg'
import sensory from '../assets/images/services/sensory-activities.svg'
import social from '../assets/images/services/social-interaction.svg'
import communication from '../assets/images/services/communication.svg'
import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop'

import { Helmet } from 'react-helmet'

const Youngt = () => {

    return (
        <>

            <ScrollToTop />
            <div id="page-section">
                <Layout title="Young Toddlers Program in Oak Harbor | Nurture with Care Kids Academy" description="Our young toddlers program provides a safe and stimulating space for your child to learn, grow, and thrive. Enroll your little one today for early childhood development programs.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor" />
                        <meta property="og:title" content="Young Toddlers Program in Oak Harbor" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="Our young toddlers program provides a safe and stimulating space for your child to learn, grow, and thrive. Enroll your little one today for early childhood development programs." />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/young-todler.053059de7e5ea92a5ff5.jpg" />
                    </Helmet>
                    <section className="services" style={{paddingTop:"0"}}>
                        <div className="container-fluid">

                            <div className="jumbo-heading">
                                <h1>Young Toddlers Program in Oak Harbor</h1>
                            </div>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>At our young children's learning center, we prioritize individualized care and early childhood development programs tailored to cater to the unique needs of toddlers. Our experienced staff, well-versed in early childhood education, comprehensively understand the nuances of nurturing young minds. We maintain a low child-to-staff ratio, ensuring that each child receives personalized attention, fostering a sense of security and trust.
                                        </p><p>
                                            Safety and exploration are central to our approach. Our carefully crafted play areas provide a secure yet stimulating environment, encouraging toddlers to explore and engage actively. These spaces are thoughtfully designed to stimulate cognitive development while ensuring a safe place for young adventurers.</p>
                                        <p>Understanding that young toddlers learn through sensory experiences, our program integrates sensory-rich activities. These activities promote sensory exploration, kindle creativity, and refine motor skills, laying a strong foundation for holistic development.</p>

                                    </div>
                                    <div className="col-md-6">
                                        <img src={yt} alt="young toddler activities" className="img-curved  center-block img-responsive" />
                                    </div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-md-6">
                                        <img src={yt2} alt="young toddler activities" className="img-curved  center-block img-responsive" />
                                    </div>
                                    <div className="col-md-6 mt-3">

                                        <p>
                                            In fostering early social skills, we facilitate gentle interactions among toddlers, encouraging the development of empathy and a sense of community. We instill valuable social skills through supervised play and engaging group activities, setting the stage for positive social interaction and communication.
                                        </p>
                                        <p>
                                            We understand working parents' demands, so we prioritize daily communication. Keeping parents and guardians informed about their child's day is crucial. Our open lines of communication ensure you stay updated on your child's meals, nap times, engaging activities, and developmental milestones achieved throughout the day.
                                        </p>
                                        <p>
                                            At our center, we offer more than childcare; we provide a nurturing environment that supports early childhood development while offering essential <a href="https://www.nurturewithcarekidsacademy.com/">childcare help for working parents</a>. Your child's growth, safety, and happiness are at the core of what we do, fostering an environment where toddlers can thrive, and parents can find peace of mind.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section className="facilities bg-color4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>Facilities for Young Toddlers</h2>

                                    <p>Our <a href="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor">early learning preschool </a>put emphasis on engaging, interesting, and age-appropriate activities for young toddlers, focusing on socialization, cognitive skills, creativity, emotional development, and so on. Nurture with Care Kids Academy wants our toddlers to develop academically, but we also ensure to make their childhood full of opportunities for exploration and fun. The academy believes in creating a nurturing and balanced atmosphere tailored to young <a href="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor">toddler education programs</a>,  developmental phases, facilitating learning through play and exploration. We make the child’s potential unfold by offering key facilities, mentioned below:
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={young} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Individualized Care</h3>
                                        <p>Our experienced caregivers understand the unique needs of young toddlers. We maintain a low child-to-caregiver ratio to ensure personalized attention.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={safe} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Safe Exploration</h3>
                                        <p> We offer a safe and stimulating space for your child to explore their surroundings. Our play areas are designed to encourage active engagement and cognitive development.</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={sensory} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Sensory Activities</h3>
                                        <p>Young toddlers learn through their senses. Our program includes sensory-rich activities that encourage sensory exploration, creativity, and motor skill development.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={social} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Introduction to Social Interaction</h3>
                                        <p> We facilitate gentle interactions between young toddlers, helping them develop social skills, empathy, and an early understanding of community.</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={communication} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Daily Communication</h3>
                                        <p> We maintain open lines of communication with parents/guardians to keep you informed about your child's day, including meals, naps, and activities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Youngt