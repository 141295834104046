// import { useState } from 'react';
// import GoogleMapReact from 'google-map-react';
// import logo from '../assets/images/logo/logo-nurture.png'

// InfoWindow component
// const InfoWindow = () => {

//     const infoWindowStyle = {
//         position: 'relative',
//         bottom: 190,
//         left: '-45px',
//         width: 250,
//         backgroundColor: 'white',
//         boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
//         padding: 10,
//         fontSize: 14,
//         zIndex: 100,
//     };

//     return (
//         <div style={infoWindowStyle}>
//             <div style={{ fontSize: 16 }}>
//                 Nuture With Care Kids Academy
//             </div>
//             <div style={{ fontSize: 14, color: 'grey' }}>
//                 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277
//             </div>
//         </div>
//     );
// };

// const LocationPin = () => {

//     const [show, setShow] = useState(false)

//     const markerStyle = {
//         height: 10,
//         width: 10,
//         cursor: 'pointer',
//         zIndex: 10,
//     };

//     function onClickCallback() {
//         console.log("asdasdasd");
//         setShow(!show)
//     }

//     return (
//         <div style={ markerStyle } onClick={onClickCallback}>
//             <img src={logo} className="img-responsive" alt="" style={{width: '100px' }} />
//             {show && <InfoWindow />}
//         </div>
//     )
// }

const Map = () => {

    // const location = {
    //     lat: 48.28469365720566,
    //     lng: -122.67222407529704,
    // }
    // const zoomLevel = 17

    return (
        <div className="container-fluid">
            <div>
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21239.30835828043!2d-122.69229772343138!3d48.28527910413366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548f8bd56549e27f%3A0xc8be9aef9af36161!2sNurture%20with%20Care%20Kids%20Academy!5e0!3m2!1sen!2sin!4v1701687589759!5m2!1sen!2sin" width="600" height="350" style={{border:"0px", width:"100%", borderRadius:"20px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            </div>
            {/* <div id="map-canvas">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyALmkmrtdUQoUEmFIyRZvCEDpaGMcZ5G08' }}
                    defaultCenter={location}
                    defaultZoom={zoomLevel}
                >
                    <LocationPin
                        lat={location.lat}
                        lng={location.lng}
                    />
                </GoogleMapReact>
            </div> */}
        </div>
    )
}

export default Map