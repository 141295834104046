
import certificate from '../meta/Certificate of Immunization of Status.pdf'
import child from '../meta/Child Care Agreement.pdf'
import cHistory from '../meta/Child History.pdf'
import consent from '../meta/Consent to Medical Care.pdf'
import disaster from '../meta/Disaster Release Form.pdf'
import medical from '../meta/Medical Information about Child.pdf'
import permission from '../meta/Pemission Authorization.pdf'
import registration from '../meta/Registration Form.pdf'
import Layout from '../components/layout';
import { Helmet } from 'react-helmet'
import ScrollToTop from '../assets/js/Scrolltotop'
const Enroll = () => {

  return (
    <>
    <ScrollToTop/>
    <div id="page-section">
      <Layout title="Enroll Your Child Today at the Top Daycares in Oak Harbor, WA | Nurture with Care Kids Academy
" description="Our dedicated staff provides top-notch childcare help for working parents. Contact us today to schedule a visit and discover the perfect place for your child's growth and development.
">

        <Helmet>
          <meta property="og:site_name" content="Nurture With Care Kids Academy" />
          <meta property="og:url" content="https://nurturewithcarekidsacademy.com/enroll" />
          <meta property="og:title" content="Enrollment Forms" />
          <meta property="og:type" content="article" />
          <meta property="og:description" content="Enroll your child in our daycare activity program " />
          <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/logo-nurture.fe8ff81585c61f3e8f0c.png" />

        </Helmet>
        <section id="blog" style={{ paddingBottom: "0", paddingTop:"0" }}>

          <div className="container-fluid">

            <div className="jumbo-heading">
              <h1>Enrollment Forms</h1>
            </div>


            <div className="container">

              <div id="blog-container" className="col-md-12">
                <div className="blog-post post-main">
                  <div className="row">
                    <div className='col-md-12 col-lg-12'>
                      <div className="tagline">If you are ready to enroll in your child, please complete the information at <a href="https://www.himama.com/online_registration/apply/nurture-with-care-kids-academy" rel='noreferrer' target="_blank">https://www.himama.com/online_registration/apply/nurture-with-care-kids-academy</a>.
                        <span>  This should not take more than a few minutes.</span>
                      </div>
                    </div>
                  </div>
                  <ul className='list-inline'>
                    <li>
                      <p>Upon submission of your information, we will promptly receive a notification and proceed to contact you regarding the subsequent procedures. Following this, we will provide a set of digital documents for your completion and online submission. Once received, we will diligently review the provided documents and proceed with the enrollment process for your child in the appropriate classroom. Your visit to our center is warmly encouraged subsequent to the initial enrollment form submission. We will reach out to you to schedule your visit.</p>
                    </li>
                    <li>
                      <p>The following is the list of documents that you are expected to complete either digitally or on paper.</p>
                      <ul style={{ listStyleType: "disc" }}>
                        <li>
                          <p>Download and complete the documents using <a className="btn-link" href="https://get.adobe.com/reader/" target='_blank' rel="noreferrer"><strong>Adobe Acrobat Reader</strong></a>, digitally sign them, and send them to the email ID on the forms</p>
                        </li>
                        OR
                        <li>
                          <p>Download the documents, print them, complete and sign them, scan and send them to the email ID on the forms, or bring a copy of the documents to the Center.</p>
                        </li>
                      </ul>
                      <ol>
                        <li><a className="btn-link" href={certificate} target="_blank" rel="noreferrer">Certificate of Immunization of Status</a></li>
                        <li><strong><a className="btn-link" href={child} target="_blank" rel="noreferrer">Child Care Agreement</a></strong></li>
                        <li><strong><a className="btn-link" href={cHistory} target="_blank" rel="noreferrer">Child History</a></strong></li>
                        <li><strong><a className="btn-link" href={consent} target="_blank" rel="noreferrer">Consent to Medical Care</a></strong></li>
                        <li><strong><a className="btn-link" href={disaster} target="_blank" rel="noreferrer">Disaster Release Form</a></strong></li>
                        <li><strong><a className="btn-link" href={medical} target="_blank" rel="noreferrer">Medical Information on Child</a></strong></li>
                        <li><strong><a className="btn-link" href={permission} target="_blank" rel="noreferrer">Permission Authorization</a></strong></li>
                        <li><strong><a className="btn-link" href={registration} target="_blank" rel="noreferrer">Registration Form</a></strong></li>

                      </ol>
                    </li>
                  </ul>



                </div>
              </div>

            </div>
          </div>

        </section>
      </Layout>
    </div>
    </>

  )
}

export default Enroll