const CustomAction = (props) => {

    const actionNumber = props.actionNumber
    const title = props.title
    const description = props.description
    const isContactButton = props.isContactButton
    const isAboutButton = props.isAboutButton
    const isPdfButton = props?.isPdfButton
    const pdfButtonUrl = props?.pdfButtonUrl

    return (
        <section id={`call-to-action${actionNumber}`} className="container-fluid small-section"
            data-center="background-position: 50% 0px;"
            data-top-bottom="background-position: 50% -20px;"
            data-bottom-top="background-position: 50% 20px;">
            <div className="gallery-container">
                <div className="text-center col-md-12">

                    <div className="well col-lg-5">
                        <h4>{title}</h4>
                        <p>{description}</p>

                        <div className="page-scroll">
                            {isContactButton && <a className="btn" href="/contact">Contact us</a>}
                            {isAboutButton && <a className="btn margin-btn" href="#about">About us</a>}
                            {isPdfButton && <a className="btn margin-btn" href={pdfButtonUrl} target="_blank" rel="noreferrer">Now Enrolling</a>}
                        </div>
                    </div>

                    <div className="col-lg-2"></div>

                    <div className="well col-lg-5 px-48">
                        <div className="text-left pt-0">
                            <div className="contact-info ">
                                <p><i className="fa fa-envelope margin-icon"></i>Email: <a href="mailto:info@nurturewithcarekidsacademy.com">info@nurturewithcarekidsacademy.com</a></p>
                                <p><i className="fa fa-phone margin-icon"></i>Phone: <a href="tel:+1 360-682-8920">+1 360-682-8920</a></p>
                                <p><i className="fa fa-map-marker margin-icon"></i>Address: 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</p>
                                <p><i className="fa fa-clock-o margin-icon"></i>Hours of Operation: 7AM - 6PM</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CustomAction