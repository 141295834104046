import Contact1 from '../components/Contact';
import bird from '../assets/images//contact/contact-bird.png'
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../assets/js/Scrolltotop';
const Contact = () => {

  return (
    <>
    <ScrollToTop/>
    <div id="page-section">
      <Layout title="Contact Us | Nurture with Care Kids Academy" description="Our dedicated staff provides top-notch childcare help for working parents. Contact us today to schedule a visit and discover the perfect place for your child's growth and development.
">
        <Helmet>
          <meta property="og:site_name" content="Nurture With Care Kids Academy" />
          <meta property="og:url" content="https://nurturewithcarekidsacademy.com/contact" />
          <meta property="og:title" content="Contact Us" />
          <meta property="og:type" content="article" />
          <meta property="og:description" content="Contact us today to schedule a visit and discover the perfect place for your child's growth and development." />
          <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/logo-nurture.fe8ff81585c61f3e8f0c.png" />
        </Helmet>
        <section id="blog" style={{paddingTop:"0"}}>

          <div className="container-fluid">
            <div className="jumbo-heading">
              <h1 className='mb-2r'>Contact Us</h1>
              <div className="hr contact-hr text-light"></div>
            </div>
            <div className="parallax-obj2 hidden-sm hidden-xs contact-para">
              <img src={bird} alt="" />
            </div>
            <div className="container">
              <div id="blog-container" className="col-md-12 contactBox">
                <div className="blog-post post-main">
                  <Contact1 />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
    </>

  )
}

export default Contact