import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './TestimonialSlider.css';

import left from "../assets/images/left-arow.svg";
import right from "../assets/images/right-arrow (2).svg"
import { Link } from 'react-router-dom';


const testimonials = [
    {
        name: "Carolyn Zook",
        rating: 5,
        text: "Our toddler has been attending Nurtured with Care for the past couple of months, and we are beyond happy with the care she receives. We are greeted with smiles at pick up and drop off, and we can see how excited our toddler is to go to school and see all her friends and teachers. We have also seen a major jump in her speech and abilities (using a crayon, painting, naming animals, eating on her own, interacting with other kids) since starting at Nurtured with Care. Her confidence has grown as well, which can be attributed to the staff’s care during her transition. The facility is always clean and our toddler comes home well taken care of. We love the open communication throughout the day, and the parent involvement in both holidays and activities. We can’t love this place and staff enough, and are grateful for the positive and safe space they have created.",
        link:"https://g.co/kgs/QSLmffY"
    },
    {
        name: "Shawna Baker",
        rating: 5,
        text: "My son has been in the program for about 6 months, from the first interaction to the last, he has loved the atmosphere as well as the staff!! I love how I am updated throughout the day from when he arrives to when he leaves as well as pictures of activities. My main reason for choosing the Kids Academy for my son was for him to have some social interaction with kids his age before preschool. We both love it!!",
        link:"https://g.co/kgs/VTP8mKA"
    },
    {
        name: "Toni Elick",
        rating: 5,
        text: "Amazing, the staff, the growth my son made, all amazing. Lee enjoyed going to school everyday. Never any complaints. Would highly recommend",
        link:"https://g.co/kgs/qBPmoxe"

    },
    {
        name: "Alexis Martinez",
        rating: 5,
        text: "My daughter loved going to daycare everyday! We both have had great experiences while she was there! The staff is always friendly, and definitely love what they do.",
        link:"https://g.co/kgs/wqwrN5R"

    },
    {
        name: "Chris Mills",
        rating: 5,
        text: "Outstanding center for little ones. The teachers are wonderful and our son loves going. Special shout out to Miss Nidhi for being such a joy to work with.",
        link:"https://g.co/kgs/9ryb5VY"

    },
    {
        name: "Tonya Aus",
        rating: 5,
        text: "So far we live the experience. Staff is very friendly. Always greet the parents. There and cameras up in the rooms for safety. They use an app to update the parents. I love getting pictures through out the day while I’m working. I am very happy that we get send our little girl here.",
        link:"https://g.co/kgs/XKGcwdw"

    }
];



const Testimonials = () => {
    const carouselRef = useRef(null);

    const handlePrevClick = () => {
        carouselRef.current.prev();
    };

    const handleNextClick = () => {
        carouselRef.current.next();
    };

    const truncateText = (text, limit) => {
        if (text.length > limit) {
            return {
                truncated: `${text.substring(0, limit)}...`,
                isTruncated: true
            };
        }
        return {
            truncated: text,
            isTruncated: false
        };
    };
    return (
        
        <div className="testimonials-container container">
            <div className="section-heading">
                <h2>Testimonials</h2>

                <div className="hr programs-hr"></div>
            </div>
            <OwlCarousel
                ref={carouselRef}
                className="owl-theme"
                loop
                nav={false}
                dots={true}
                items={2}
                autoplay={true}
                autoplayTimeout={4000}
                responsive={{
                    0: {
                        items: 1
                    },
                    600: {
                        items: 2,
                    }
                }}
            >
                 {testimonials.map((testimonial, index) => {
                    const { truncated, isTruncated } = truncateText(testimonial.text, 150);
                    return (
                        <div className="testimonial-item" key={index}>
                            <div className="testimonial-content">
                                <h3 className="testimonial-name">{testimonial.name}</h3>
                                <div className="testimonial-rating">
                                    {'★'.repeat(testimonial.rating)}
                                </div>
                                <p className="testimonial-text">
                                    {truncated} 
                                    {isTruncated && (
                                        <Link to={testimonial.link} className="read-more" target='_blank'>Read More</Link>
                                    )}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </OwlCarousel>
            <div className="custom-owl-dots">
                <div className="left-owl-arrow" onClick={handlePrevClick} style={{cursor:'pointer'}}>
                    <img src={left} alt="" />
                </div>
                <div className="right-owl-arrow" onClick={handleNextClick} style={{cursor:'pointer'}}>
                    <img src={right} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
