import Accordion from 'react-bootstrap/Accordion';

function BasicExample() {
        return (
                <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                                <Accordion.Header>1. What is the average cost of daycare in Washington state?</Accordion.Header>
                                <Accordion.Body>
                                        The cost of <a href="https://www.nurturewithcarekidsacademy.com/">daycare in the Oak Harbor, WA</a> area can fluctuate based on the type of childcare
                                        and the child&#39;s age. Typically, families might anticipate a monthly expense of around $900 to
                                        $1,200 for full-time toddler care. For programs with additional educational features, the cost
                                        might be somewhat higher. Please be aware that this information is provided for reference, and
                                        it is advisable to conduct your own research for the most accurate and current data.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                                <Accordion.Header>2. What is the best age to put a child in daycare?</Accordion.Header>
                                <Accordion.Body>
                                        The ideal age for <a href="https://nurturewithcarekidsacademy.com/enroll">daycare enrollment</a> varies based on parental circumstances and the child&#39;s
                                        needs. Nurture with Care Kids Academy welcomes children aged 1-6. Consider the following:
                                        <ul>
                                                <li>Infant Care: Many daycares accept infants from six weeks old, suitable for working parents
                                                        returning from leave.</li>
                                                <li>Toddler and Preschool Age (1-3 years): Social interaction and early education benefit
                                                        toddlers.</li>
                                                <li>Pre-Kindergarten (4-5 years): Preparing children for elementary school.</li>
                                                <li>Parental Needs: Full-time working parents may enroll children earlier.</li>
                                                <li>Developmental Milestones: Assess your child&#39;s readiness for a group setting.</li>
                                        </ul>
                                        Ultimately, the best age to start daycare depends on family needs and a child&#39;s development.
                                        Research local options and work schedules for an informed decision.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                                <Accordion.Header>3. How many hours should a 2-year-old spend in daycare?</Accordion.Header>
                                <Accordion.Body>
                                        The hours a 2-year-old spends at Nurture with Care Kids Academy or any daycare facility
                                        depend on family needs. Consider:
                                        <ul>
                                                <li>Part-Time vs. Full-Time: Some prefer 4-6 hours/day for socialization and family time.</li>
                                                <li>Full-Day Programs (8-10 hours): Suitable for working parents, providing structure and
                                                        activities.</li>
                                                <li>Flexibility: Align hours with parents&#39; schedules; Nurture with Care Kids Academy offers
                                                        flexibility.</li>
                                                <li>Socialization and Learning: The center focuses on engaging and educational experiences.</li>
                                                <li>Child&#39;s Needs: Cater to your child&#39;s temperament; some may thrive with shorter hours.</li>
                                        </ul>
                                        Choose a daycare like Nurture with Care Kids Academy that meets your family&#39;s needs and
                                        offers a nurturing and stimulating environment. Balance developmental needs, work schedules,
                                        and quality care.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                                <Accordion.Header>4. What are good learning activities for toddlers?</Accordion.Header>
                                <Accordion.Body>
                                        Engaging young children in learning activities is vital for their development. Nurture with Care
                                        Kids Academy offers a nurturing environment with effective <a href="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor">toddler childcare activities</a>:
                                        <ul>
                                                <li>Storytime: Fosters language skills and a love for books.</li>
                                                <li>Art and Creativity: Encourages fine motor skills and creativity.</li>
                                                <li>Music and Movement: Enhances physical coordination and rhythm.</li>
                                                <li>Outdoor Play: Vital for physical and sensory development.</li>
                                                <li>Sensory Play: Develops fine motor skills through playdough and sand.</li>
                                                <li>Counting and Sorting: Introduces basic math concepts.</li>
                                                <li>Pretend Play: Encourages creativity and social skills.</li>
                                                <li>Puzzles and Games: Enhance problem-solving and cognition.</li>
                                                <li>Language Development: Promotes communication and self-expression.</li>
                                        </ul>
                                        Nurture with Care Kids Academy ensures a balanced approach to early childhood education
                                        in WA, encompassing cognitive, physical, social, and emotional development.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                                <Accordion.Header>5. What do you teach toddlers in daycare?</Accordion.Header>
                                <Accordion.Body>
                                        In high-quality daycare settings like Nurture with Care Kids Academy, <a href="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor">early childhood
                                                development programs</a> prioritize engaging, age-appropriate activities for toddlers, focusing on
                                        key elements:
                                        <ul>
                                                <li>Language Development: Nurture with Care Kids Academy emphasizes building vocabulary
                                                        and communication through conversations, stories, and songs.</li>
                                                <li>Socialization: Interaction with peers fosters sharing, cooperation, and social skills, promoting
                                                        healthy development.</li>
                                                <li>Cognitive Skills: Toddlers learn basic cognitive concepts like colors, shapes, and numbers,
                                                        incorporating counting games and puzzles.</li>
                                                <li>Motor Skills: Fine motor skills improve through art and outdoor play, while gross motor skills
                                                        develop via running, jumping, and climbing.</li>
                                                <li>Creativity: Art and creative activities stimulate self-expression and imaginative thinking.</li>
                                                <li>Sensory Exploration: Sensory play enhances fine motor skills and sensory development.</li>
                                                <li>Emotional Development: Nurture with Care Kids Academy provides a nurturing environment
                                                        for emotional management and security.</li>
                                                <li>Pre-Literacy Skills: Early literacy is encouraged through storytelling, fostering an interest in
                                                        books and basic pre-writing skills.</li>
                                                <li>Problem-Solving: Age-appropriate puzzles and games promote cognitive development and
                                                        problem-solving.</li>
                                        </ul>
                                        Daycare centers like Nurture with Care Kids Academy create a balanced and nurturing
                                        environment, tailored to each child&#39;s developmental stage, facilitating learning through play and
                                        exploration.
                                </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="5">
                                <Accordion.Header>6. How Is Child Care Regulated to Ensure Children’s Health and Safety?</Accordion.Header>
                                <Accordion.Body>
                                        <a href="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor">Preschools in Oak Harbor, WA</a>,is strictly regulated to prioritize children&#39;s safety and well-
                                        being. Nurture with Care Kids Academy, for instance, strictly complies with these vital
                                        regulations to offer a secure environment. Key aspects of childcare regulation include:
                                        <ul>
                                                <li>Licensing: Providers like Nurture with Care Kids Academy must obtain licenses, issued by
                                                        state and local authorities, ensuring adherence to specific health and safety standards.</li>
                                                <li>Staff Qualifications: Regulations mandate background checks, training, and staff-to-child
                                                        ratios to meet educational and safety requirements.</li>
                                                <li>Safety Measures: Childproofing, secure entry points, fire safety, and regular drills maintain a
                                                        safe environment.</li>
                                                <li>Health and Sanitation: Regular cleaning and sanitization, including toys and play areas, is
                                                        required, with staff trained in hygiene and illness prevention.</li>
                                                <li>Nutrition: Childcare centers ensure children receive nutritious meals, accommodating dietary
                                                        needs.</li>
                                                <li>Curriculum: Developmentally appropriate curricula, like Nurture with Care Kids Academy&#39;s,
                                                        support cognitive, emotional, and social development.</li>
                                                <li>Healthcare Policies: Protocols for medical emergencies, illness management, and updated
                                                        medical records are essential.</li>
                                                <li>Parent Communication: Open parent communication, via newsletters, meetings, and daily
                                                        updates, is maintained.</li>
                                                <li>Regular Inspections: Licensing agencies conduct routine inspections for compliance.</li>
                                                <li>Emergency Plans: Childcare centers have emergency procedures for various situations,
                                                        such as medical emergencies and natural disasters.</li>
                                        </ul>
                                        Adhering to these regulations, Nurture with Care Kids Academy and other providers ensure
                                        children&#39;s health and safety remains paramount. Parents should confirm that their chosen facility
                                        complies with local regulations for a nurturing and secure childcare environment.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                                <Accordion.Header>7. How Do I Find and Choose Quality Child Care?</Accordion.Header>
                                <Accordion.Body>
                                        Selecting quality childcare, like Nurture with Care Kids Academy in Oak Harbor, WA, is crucial
                                        for your child&#39;s development. Follow these steps:
                                        <ul>
                                                <li>Research and Referrals: Begin by researching and seeking referrals from friends or
                                                        parenting forums.</li>
                                                <li>Licensing and Accreditation: Check if the facility is licensed and accredited to ensure it
                                                        meets safety and educational standards.</li>
                                                <li>Visit the Facility: Observe the environment, staff, and curriculum during visits.</li>
                                                <li>Staff Qualifications: Inquire about staff qualifications in child development and early
                                                        education.</li>
                                                <li>Curriculum: Ensure the curriculum supports early learning and social development.</li>
                                                <li>Teacher-to-Child Ratios: Lower ratios offer a more personalized learning environment.</li>
                                                <li>Health and Safety: Learn about safety measures and illness policies.</li>
                                                <li>Parent Involvement: Consider the level of parent involvement and communication.</li>
                                                <li>Observation: Watch how children engage in activities and their well-being.</li>
                                                <li>References: Seek feedback from other parents who have used Nurture with Care Kids
                                                        Academy&#39;s services.</li>
                                                <li>Trust Your Instincts: Ultimately, choose a center that aligns with your values and provides
                                                        comfort in leaving your child. Thorough research ensures an informed decision supporting
                                                        your child&#39;s development.</li>
                                        </ul>

                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                                <Accordion.Header>8. What is the tuition payment policy? Will I be charged late fees?</Accordion.Header>
                                <Accordion.Body>
                                        Nurture with Care Kids Academy, a trusted young children&#39;s learning center and Oak Harbor
                                        Preschool, offers a bi-weekly tuition payment policy to aid families in managing financial
                                        commitments. A clear late fee policy, outlined in our parent handbook, ensures timely payments,
                                        enabling uninterrupted education and care. We recommend parents review the handbook for a
                                        full understanding, including late fee details, to stay informed and compliant with the payment
                                        schedule. Our goal is to provide a seamless learning experience for your child while maintaining
                                        a transparent financial arrangement. We also encourage direct autopay from the bank account
                                        for added convenience.

                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                                <Accordion.Header>9. Do you take children on subsidy?</Accordion.Header>
                                <Accordion.Body>
                                        At Nurture with Care Kids Academy, we understand the importance of providing access to
                                        quality early education and childcare services for all families. We do admit children who are
                                        part of subsidy programs. We recognize that preschools and <a href="https://www.nurturewithcarekidsacademy.com/">daycares in Oak Harbor, WA</a>, can
                                        be crucial for families seeking support, and we are committed to working with families who rely
                                        on subsidies to ensure that their children have access to a nurturing and educational
                                        environment.

                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                                <Accordion.Header>10. Does the preschool offer special classes within the curriculum?</Accordion.Header>
                                <Accordion.Body>
                                        Nurture with Care Kids Academy is dedicated to providing a comprehensive and enriching
                                        educational experience. Our preschool learning center offers specialized classes within the
                                        curriculum for holistic development:
                                        <ul>
                                                <li>Art and Creativity: Fosters creativity and fine motor skills.</li>
                                                <li>Music and Movement: Enhances physical coordination and rhythm.</li>
                                                <li>Language Enrichment: Develops vocabulary, communication skills, and a love for reading.</li>
                                                <li>Science and Nature: Encourages curiosity and exploration.</li>
                                                <li>Physical Education: Promotes an active and healthy lifestyle.</li>
                                                <li>Math and Problem-Solving: Introduces age-appropriate math concepts.</li>
                                                <li>Foreign Languages: Familiarizes children with diverse languages and cultures.</li>
                                        </ul>
                                        At Nurture with Care Kids Academy, we aim to provide a well-rounded early education
                                        experience, fostering a love for knowledge while supporting social, emotional, and cognitive
                                        growth.

                                </Accordion.Body>
                        </Accordion.Item>

                </Accordion>
        );
}

export default BasicExample;