import './assets/css/Plugin.css';
import './assets/css/MainColor.css';
import './assets/css/App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavbarNav from './components/NavbarNav';
import NewsLetter from './components/NewsLetter';
import Footer from './components/Footer';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Enroll from './pages/Enroll';
import Career from './pages/Career';
import Contact from './pages/Contact';
import Newsletter from './pages/Newsletter';
import Faqs from './pages/Faqs';
import Youngt from './pages/Young Toddlers';
import Toddlers from './pages/Toddlers';
import Preschool from './pages/Preschool';
import NotFound from './pages/NotFound';
import Gallery from './pages/Gallery';

function App() {
  return (
    <Router>
      <div className="App" id="home-top-page">
        <div id="page-width">
          <NavbarNav />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/terms' element={<Terms />} />
            <Route exact path='/privacy' element={<Privacy />} />
            <Route exact path='/enroll' element={<Enroll />} />
            <Route exact path='/career' element={<Career />} />
            <Route exact path='/contact' element={<Contact />} />
            <Route exact path='/gallery' element={<Gallery/>} />
            <Route exact path='/programs/young-toddlers-in-oak-harbor' element={<Youngt />} />
            <Route exact path='/programs/toddlers-in-oak-harbor' element={<Toddlers />} />
            <Route exact path='/programs/preschool-in-oak-harbor' element={<Preschool />} />
            <Route exact path='/newsletter/:nlMonth' element={<Newsletter />} />
            <Route exact path='/faqs' element={<Faqs />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
