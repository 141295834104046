// Layout.js

import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ title, description, children }) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window?.location?.pathname} />
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
