import React from 'react';
import "../assets/css/NotFoundPage.css"
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <section className='notfound-section'>
            <h3>Oops, This Page Couldn't Be Found.</h3>
            <div className="notfound-container">
                <h1>404</h1>
                <ul className="notfound-page-links">
                    <h5>Helpful Links</h5>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/enroll">Enroll</Link></li>
                    <li><Link to="/programs/young-toddlers-in-oak-harbor">Young Toddlers Program</Link></li>
                    <li><Link to="/programs/toddlers-in-oak-harbor">Toddlers Program</Link></li>
                    <li><Link to="/programs/preschool-in-oak-harbor">Preschool Program</Link></li>
                </ul>
            </div>
        </section>
    )
}

export default NotFound