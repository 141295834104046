import parentHandbook from '../meta/NurturewithCareParenthandbook-v2.1_compressed.pdf'
import healthPolicy from '../meta/Nurture_with_Care_Health_care_policy.pdf'
import emergency from '../meta/Nurture_with_Care_Emergency_preparedness_Plan.pdf'
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="col-md-4">
          <h6 className="text-black">Nurture With Care Kids Academy</h6>
          <p className="text-black">Welcome to Nurture with Care Kids Academy, where every child's journey is embraced with love, growth, and a passion for learning. We believe that a nurturing environment lays the foundation for a lifetime of success, and it's our privilege to be a part of your child's early years.
          </p>
        </div>

        <div className="col-md-5 res-margin">
          <ul className="list-unstyled footer-icons">
            <li className="text-black"><i className="fa fa-phone"></i>Phone: <a href="tel:+1 360-682-8920">+1 360-682-8920</a></li>
            <li className="text-black"><i className="fa fa-envelope"></i>Email: <a href="mailto:info@nurturewithcarekidsacademy.com">info@nurturewithcarekidsacademy.com</a></li>
            <li className="text-black"><i className="fa fa-map-marker"></i>Address 1: 1162 SW Fort Nugent Ave, Oak Harbor, WA 98277</li>
            <li className="text-black"><i className="fa fa-clock-o"></i>Hours of Operation: 7AM – 6PM</li>
            <li className="text-black"><i className="fa fa-map-marker"></i>Address 2: 1117 View Ridge Dr, Oak Harbor, WA 98277</li>
            <li className="text-black"><i className="fa fa-clock-o"></i>Hours of Operation: 8AM – 5PM</li>
          </ul>
          <div className="social-media smaller text-left text-black">
            {/* <a href="#" title=""><i className="fa fa-twitter"></i></a> */}
            <a href="https://www.facebook.com/NurturewithCareKidsAcademy/" target="_blank" rel="noreferrer" title="" className="text-black"><i className="fa fa-facebook text-black"></i></a>
            {/* <a href="#" title=""><i className="fa fa-linkedin"></i></a>
                  <a href="#" title=""><i className="fa fa-pinterest"></i></a> */}
            <a href="https://www.instagram.com/nwc.oakharbor/" target="_blank" rel="noreferrer" title="" className="text-black"><i className="fa fa-instagram text-black"></i></a>
          </div>
        </div>


        <div className="col-md-3">

          <h6 className="text-black">Useful Resources</h6>

          <ul className="custom no-margin text-normal text-black text-left">
            <li><Link to="/faqs" rel="noreferrer">FAQ</Link></li>
            <li><a href={parentHandbook} target="_blank" rel="noreferrer">Parent Handbook</a></li>
            <li><a href={healthPolicy} target="_blank" rel="noreferrer">Health Policy</a></li>
            <li><a href={emergency} target="_blank" rel="noreferrer">Emergency Preparedness Plan</a></li>
            <li><Link to="/terms" rel="noreferrer">Terms of Service</Link></li>
            <li><Link to="/privacy" rel="noreferrer">Privacy Policy</Link></li>
            <li><Link to="/career" rel="noreferrer">Career</Link></li>
          </ul>
        </div>

        <div className="credits col-md-12 text-center text-black">
          <hr />
          {`Copyright © ${new Date().getFullYear()} Nurture with Care Kids Academy. All rights reserved.`}

          <div className="page-scroll hidden-sm hidden-xs">
            <a href="#page-top" className="back-to-top "><i className="fa fa-angle-up text-black"></i></a>
          </div>
        </div>

      </div>

    </footer>
  )
}

export default Footer