import preschool1 from '../assets/images/services/pre-school-program2.jpg'
import preschool2 from '../assets/images/services/pre-school-oak.png'
import structured from '../assets/images/services/structured-earning.svg'
import numeracy from '../assets/images/services/numeracy-skills.svg'
import critical from '../assets/images/services/critical.svg'
import character from '../assets/images/services/character.svg'
import themed from '../assets/images/services/themed.svg'
import parent from '../assets/images/services/parent.svg'

import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop'
import { Helmet } from 'react-helmet'
const Preschool = () => {

    return (
        <>
            <ScrollToTop />

            <div id="page-section">
                <Layout title="PreSchool Program in Oak Harbor | Nurture With Care Kids Academy" description="Discover the best early learning preschool with a nurturing environment and experienced teachers at Nurture With Care Kids Academy. Enroll your child today.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor" />
                        <meta property="og:title" content="Preschool Program in Oak Harbor" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="Discover the best early learning preschool with a nurturing environment and experienced teachers at Nurture With Care Kids Academy. " />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/preschool.3d02f9e76d3c797fc013.jpg" />

                    </Helmet>
                    <section className="services" style={{paddingTop:"0"}}>

                        <div className="container-fluid">

                            <div className="jumbo-heading">
                                <h1>Preschool Program in Oak Harbor</h1>

                            </div>


                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Welcome to our preschool learning center, an early learning preschool in Oak Harbor, WA, dedicated to nurturing young minds through an enriching and comprehensive curriculum.
                                        </p><p>
                                            At our center, structured learning forms the backbone of our approach. Our curriculum spans language, math, science, and more, offering age-appropriate activities that inspire and challenge children, ensuring a well-rounded educational experience.
                                        </p>
                                        <p>
                                            Early literacy and numeracy skills are foundational in our program. We place a strong emphasis on building these essential skills, fostering a solid base for academic success. Through engaging activities tailored to their developmental stage, children develop a love for reading, numeracy, and learning.
                                        </p>
                                        <p>Encouraging critical thinking and problem-solving abilities is integral to our curriculum. Hands-on activities, puzzles, and games stimulate cognitive growth, fostering a mindset that values inquiry, exploration, and finding solutions.</p>

                                    </div>
                                    <div className="col-md-6">
                                        <img src={preschool1} alt="preschool in oak harbor" className="img-curved  center-block img-responsive" />
                                    </div>
                                </div>

                                <div className="row mt-30">
                                    <div className="col-md-6">
                                        <img src={preschool2} alt="preschool in oak harbor" className="img-curved  center-block img-responsive" />
                                    </div>
                                    <div className="col-md-6 mt-3">

                                        <p>
                                            Character development is woven into our daily activities. Through stories, discussions, and cooperative projects, we cultivate traits such as kindness, empathy, and responsibility, nurturing children to become well-rounded individuals with strong moral values.


                                        </p>
                                        <p>
                                            Project-based learning forms an integral part of our approach. Themed projects encourage research, collaboration, and creativity, providing children with opportunities to explore various subjects and develop a diverse skill set.
                                        </p>
                                        <p>
                                            We understand the importance of parent involvement in a child's educational journey. Our center maintains open communication with parents/guardians, providing regular updates on their child's progress and involving families in various aspects of the learning process, ensuring a collaborative and supportive environment.
                                        </p>
                                        <p>As one of the leading <a href="https://nurturewithcarekidsacademy.com/">daycares in Oak Harbor, WA</a>, our preschool learning center is dedicated to providing a nurturing and stimulating environment where children flourish academically, socially, and emotionally. We strive to instill a love for learning and lay a strong foundation for lifelong success.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section className="facilities bg-color4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>Facilities for Preschoolers </h2>
                                    <p>Nurture With Care Kids Academy provides everything parents want in an early learning preschool for their children. We feel proud of our dedicated teachers, who understand children’s different educational needs and are committed to providing age-appropriate education and the best care in a more positive and safe environment. Our <a href="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor">early childhood learning center</a> holds a reputation in the field of early education, learning, and human development. Let’s check out the facilities provided at Nurture with Care Kids Academy for Preschoolers!
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={structured} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Structured Learning</h3>
                                        <p>Our curriculum follows a structured approach that encompasses language, math, science, and more. Children engage in age-appropriate activities that challenge and inspire them.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={numeracy} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Literacy and Numeracy</h3>
                                        <p> We place a strong emphasis on building early literacy and numeracy skills, fostering a solid foundation for academic success.</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={critical} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Critical Thinking</h3>
                                        <p>Our program encourages critical thinking and problem-solving through hands-on activities, puzzles, and games that stimulate cognitive growth.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={character} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Character Development</h3>
                                        <p> We foster character traits such as kindness, empathy, and responsibility through stories, discussions, and cooperative projects.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={themed} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Project-Based Learning</h3>
                                        <p> Children participate in themed projects that encourage research, collaboration, and creativity, promoting a well-rounded skill set.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={parent} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Parent Involvement</h3>
                                        <p> We maintain open communication with parents/guardians, providing regular updates on your child's progress and involving families in various aspects of their learning journey.
                                        </p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Preschool