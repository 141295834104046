import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import slide1 from '../assets/images/banner/Childcare in Oak Habor.jpg';
import slide4 from '../assets/images/banner/Nurture-kids-care-image-resize-2.png'
import slide2 from '../assets/images/banner/website banner (1).webp'


const MainBanner = () => {

    return (

        <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            loop
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
        >
            <SwiperSlide>
                <div className="main-slide">
                    <img src={slide4} className="img-responsive banner-img" alt="best childcare in Oak Harbor" />
                    <div className="slide-item header-text remove-bg">
                        <span className='text-light h5-text px-15 black-bg border-radius'>Guiding Tiny Steps Into a</span> <br />
                        <span className='text-light h5-text px-15 black-bg border-radius'>Bright Future</span>
                        <br />
                        <div className="page-scroll ">
                            <a className="btn" href="/contact">Contact Us</a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className="main-slide">
                    <img src={slide1} className="img-responsive banner-img" alt="childcare providers in Oak Harbor" />
                    <div className="slide-item header-text remove-bg">
                        <span className='text-light h5-text px-15 black-bg border-radius'>Guiding Tiny Steps Into a</span> <br />
                        <span className='text-light h5-text px-15 black-bg border-radius'>Bright Future</span>
                        <br />
                        <div className="page-scroll ">
                            <a className="btn" href="/enroll" target="_blank">Now Enrolling</a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className="main-slide">
                    <img src={slide2} className="img-responsive banner-img" alt="childcare providers in Oak Harbor" />
                    <div className="slide-item header-text remove-bg">
                        <span className='text-light h5-text px-15 black-bg border-radius'>Guiding Tiny Steps Into a</span> <br />
                        <span className='text-light h5-text px-15 black-bg border-radius'>Bright Future</span>
                        <br />
                        <div className="page-scroll ">
                            <a className="btn" href="https://www.himama.com/online_registration/apply/nurture-with-care-kids-academy" target="_blank" rel='noreferrer'>Request Childcare</a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

        </Swiper>















    )
}

export default MainBanner
