
import boy1ornament from '../assets/images/about-girl.png'
import welcome from '../assets/images/welcome.jpg'
import mission from '../assets/images/Our mission 518x518.jpg'
import choose from '../assets/images/Why choose us 518x518.jpg'
import philosphy from '../assets/images/Our Philosophy 518x518.jpg'
import programs from '../assets/images/Facilities and Programs 518x518.jpg'

import { useState } from 'react';

const About = () => {

  const iniTab = {
    tab1: '',
    tab2: '',
    tab3: '',
    tab4: '',
    tab5: '',
  }

  const getTabValue = (tab) => {

    const newTab = { ...iniTab }
    newTab[tab] = 'active in'

    return newTab
  }

  const [tab, setTab] = useState(getTabValue('tab1'))

  const onHandleTab = (tab) => {
    setTab(getTabValue(tab))
  }


  return (
    <section id="about" className="container-fluid">

      <div className="section-heading">
        <h1 style={{marginBottom:"20px"}}>Daycare in Oak Harbor</h1>

        <div className="hr about-hr"></div>
      </div>

      <div className="parallax-obj2 hidden-sm hidden-xs about-para" data-0="opacity:1;"
        data-100="transform:translatey( 40%);"
        data-center-center="transform:translatey(-60%);">

        <img src={boy1ornament} alt="licensed daycare providers near Oak Harbor" />
      </div>
      <div className="container">
        <div className="col-md-12 col-centered">
          <div className="tab" role="tabpanel">

            <ul className="nav nav-tabs mr-halfr">
              <li role="presentation" className={tab.tab1 + ""}><button className='btn-link' onClick={() => onHandleTab('tab1')}>Welcome</button></li>
              <li role="presentation" className={tab.tab2 + ""}><button className='btn-link' onClick={() => onHandleTab('tab2')}>Our Mission</button></li>
              <li role="presentation" className={tab.tab3 + ""}><button className='btn-link' onClick={() => onHandleTab('tab3')}>Why Choose Us</button></li>
              <li role="presentation" className={tab.tab4 + ""}><button className='btn-link' onClick={() => onHandleTab('tab4')}>Our Philosophy</button></li>
              <li role="presentation" className={tab.tab5 + ""}><button className='btn-link' onClick={() => onHandleTab('tab5')}>Facilities and Programs</button></li>
            </ul>
            <div className="tab-content">
              <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab1}`}>
                <div className="col-lg-7">
                  <h3 className="text-center">Welcome</h3>
                  <p>Welcome to Nurture with Care Kids Academy, where every child's journey is embraced with love, growth, and a passion for learning. We believe that a nurturing environment lays the foundation for a lifetime of success, and it's our privilege to be a part of your child's early years.</p>
                </div>
                <div className="col-lg-5">
                  <img src={welcome} alt="young toddler activities in Oak Harbor" className="img-curved  center-block img-responsive" />
                </div>
              </div>
              <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab2}`}>

                <div className="col-lg-5 margin1">
                  <img src={mission} alt="young children's learning center in Oak Harbor" className="img-curved center-block img-responsive" />
                </div>
                <div className="panel-group col-lg-7 margin1">
                  <h3 className="text-center">Our Mission</h3>
                  <p>At Nurture with Care Kids Academy, our mission is to provide a safe and stimulating haven for children to explore, learn, and flourish along with providing childcare help for working parents. We are dedicated to fostering a love for learning, while also nurturing essential social and emotional skills that form the basis for well-rounded development.</p>

                </div>
              </div>
              <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab3}`}>


                <div className="panel-group col-lg-7 margin1" >
                  <h3 className="text-center">Why Choose Us</h3>
                  <ul className="custom no-margin text-normal">
                    <li><strong>Experienced and Caring Team:</strong> Our team of experienced educators and caregivers is committed to creating a warm and welcoming environment where every child feels valued and cherished. </li>
                    <li><strong>Holistic Approach:</strong> We understand that each child is unique. Our holistic approach to education focuses on intellectual, emotional, social, and physical development, ensuring every aspect of their growth is nurtured.</li>
                    <li><strong>Engaging Curriculum:</strong> Our curriculum is designed to inspire curiosity and creativity. We blend structured learning with play-based exploration, tailoring activities to the individual needs and interests of each child.</li>
                    <li><strong>Safe and Stimulating Environment:</strong> We take pride in providing a secure and hygienic space where children can safely explore, discover, and engage in age-appropriate young toddler activities that fuel their imagination.</li>
                    <li><strong>Parent Partnership:</strong> We believe that a strong partnership with parents is essential. Regular communication, parent involvement activities, and open dialogues ensure that we work together to support each child's development.</li>
                  </ul>

                </div>

                <div className="col-lg-5 margin1">
                  <img src={choose} alt="young toddler activities for daycare in Oak Harbor" className="img-curved center-block img-responsive" />
                </div>
              </div>

              <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab4}`}>
                <div className="col-lg-5 margin1">
                  <img src={philosphy} alt="early childhood education wa" className="img-curved center-block img-responsive" />
                </div>

                <div className="panel-group col-lg-7 margin1">
                  <h3 className="text-center">Our Philosophy</h3>
                  <p>Our philosophy is rooted in the belief that children are natural learners, and the early years are a crucial time for building a strong foundation. We embrace a child-centered approach, respecting each child's pace and interests, and guiding them to become independent thinkers and confident individuals</p>

                </div>


              </div>

              <div role="tabpanel" className={`tab-pane fade col-lg-12 ${tab.tab5}`}>


                <div className="panel-group col-lg-7 margin1" >
                  <h3 className="text-center">Facilities and Programs</h3>
                  <p>From our thoughtfully designed classrooms to our enriching programs for various age groups, our best childcare near Oak Harbor provides a nurturing and inspiring environment that encourages exploration, discovery, and growth.</p>
                  <p>Join us on this exciting journey of nurturing with care and watch your child's potential unfold. We invite you to visit our preschool learning center, meet our passionate team, and experience firsthand the love and dedication that make Nurture with Care Kids Academy a second home for your little ones.</p>
                </div>

                <div className="col-lg-5 margin1">
                  <img src={programs} alt="day care activities for toddlers near Oak Harbor" className="img-curved center-block img-responsive" />
                </div>
              </div>
            </div>


          </div>


        </div>

      </div>

    </section>
  )
}

export default About