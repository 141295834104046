import young from '../assets/images/services/pre-school-program1.jpg'
import young1 from '../assets/images/services/young-oak.png'
import curriculum from '../assets/images/services/curriculum.svg'
import language from '../assets/images/services/language.svg'
import creative from '../assets/images/services/creative.svg'
import social from '../assets/images/services/social-interaction.svg'
import potty from '../assets/images/services/potty.svg'


import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop'
import { Helmet } from 'react-helmet'
const Toddlers = () => {

    return (
        <>
            <ScrollToTop />
            <div id="page-section">
                <Layout title="Toddlers Program in Oak Harbor | Nurture with Care Kids Academy" description="With a focus on learning through play, our experienced teachers help them learn with daycare activities for toddlers. Enroll your child today at the best toddler daycare.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor" />
                        <meta property="og:title" content="Toddlers Program in Oak Harbor" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="With a focus on learning through play, our experienced teachers help them learn with daycare activities for toddlers." />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/young.247b5af37da4e6eea84d.jpg" />

                    </Helmet>
                    <section className="services" style={{paddingTop:"0"}}>

                        <div className="container-fluid">

                            <div className="jumbo-heading">
                                <h1>Toddlers Program in Oak Harbor</h1>

                            </div>


                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Welcome to our toddler learning center, where we provide the best in toddler daycare in Oak Harbor, WA. Our commitment lies in offering a nurturing environment that fosters holistic development through a thoughtfully curated curriculum designed for toddlers.
                                        </p><p>
                                            Central to our approach is an age-appropriate curriculum meticulously crafted to stimulate cognitive, language, and motor skill development. We strike a balance between structured learning and play-based exploration, ensuring that each child thrives in a dynamic and engaging environment.</p>
                                        <p>Language development holds a key place in our program. Through captivating stories, interactive songs, and engaging activities, we focus on expanding toddlers' vocabularies and honing their communication skills. This emphasis lays a solid foundation for future learning and academic success.
                                        </p>

                                    </div>
                                    <div className="col-md-6">
                                        <img src={young} alt="toddler activities" className="img-curved  center-block img-responsive" />
                                    </div>
                                </div>

                                <div className="row mt-30">
                                    <div className="col-md-6">
                                        <img src={young1} alt="toddler activities" className="img-curved  center-block img-responsive" />
                                    </div>
                                    <div className="col-md-6 mt-3">

                                        <p>
                                            Encouraging creative expression is vital in our curriculum. We provide ample opportunities for artistic exploration through art, music, and movement activities. These avenues allow toddlers to unleash their creativity, fostering self-expression and confidence.

                                        </p>
                                        <p>
                                            Social skills are nurtured through carefully crafted group activities. From promoting sharing and taking turns to encourage cooperative play, these experiences lay the groundwork for essential social skills and emotional development, preparing toddlers for future interactions and friendships.
                                        </p>
                                        <p>
                                            Understanding the milestones of potty training, our experienced staff offers gentle support for toddlers who are ready to take this step. Working hand-in-hand with parents and guardians, our team provides consistent and encouraging guidance, ensuring a positive and supportive transition.
                                        </p>
                                        <p>As one of the best <a href="https://www.nurturewithcarekidsacademy.com/">daycares in Oak Harbor, WA</a>, we take pride in offering a safe, engaging, and supportive environment that prioritizes the growth and development of toddlers. Our commitment extends beyond childcare; it's about fostering a love for learning, socialization, and self-expression, ensuring that each toddler flourishes in a unique way.
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </section>
                    <section className="facilities bg-color4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>Facilities for Toddlers </h2>

                                    <p>To facilitate a quality learning atmosphere, Nurture with Care Kids Academy offers <b>daycare activities for toddlers</b> to provide child-centered and growth-minded care. These activities make your children capable of exploring the world in their own way. We aimed to develop a strong foundation for logical reasoning in toddlers to enhance their basic skills. Your children are in loving and capable hands at Nurture with Care Kids Academy. Let’s check out the daycare activities provided at Nurture with Care Kids Academy for toddlers!
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={curriculum} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Age-Appropriate Curriculum</h3>
                                        <p>Our curriculum is designed to stimulate cognitive, language, and motor skill development. We offer a blend of structured learning and play-based exploration.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={language} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Language Development</h3>
                                        <p>  Through stories, songs, and engaging activities, we focus on expanding toddlers' vocabulary and communication skills, setting the foundation for future <a href="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor"> early childhood development programs.</a></p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={creative} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Creative Expression</h3>
                                        <p>Toddlers love to express themselves. Our program includes art, music, and movement activities that encourage creativity and self-expression.

                                        </p>
                                    </div>
                                </div>


                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={social} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Social Skills</h3>
                                        <p> Toddlers are introduced to group activities that promote sharing, taking turns, and cooperative play. These experiences foster essential social skills and emotional development.</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={potty} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Potty Training Support</h3>
                                        <p> For those who are ready, our experienced staff provides gentle potty training support, working in partnership with parents/guardians.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Toddlers