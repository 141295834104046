import ScrollToTop from "../assets/js/Scrolltotop"


const Terms = () => {

  return (
    <>
       <ScrollToTop/>
      <div id="page-section">
        <section id="blog" style={{paddingTop:"0"}}>

          <div className="container-fluid">

            <div className="jumbo-heading">
              <h1>Terms of Services</h1>
            </div>


            <div className="container">
              <div id="blog-container" className="col-md-12">
                <div className="blog-post post-main">

                  <p>Welcome to Nurture with Care Kids Academy! These terms and conditions ("Terms") outline the rules and regulations for using our website and services. By accessing this website, we assume you accept these Terms. Do not continue to use Nurture with Care Kids Academy's website if you do not agree to all the terms and conditions stated on this page.</p>

                  <ol>
                    <li className="mb-2">
                      <strong>Use of Website</strong>
                      <br />
                      The content of this website is for general information purposes only and is subject to change without notice. We do not provide any guarantee or warranty as to the accuracy, timeliness, performance, completeness, or suitability of the information found on this website.
                      <br />
                      Your use of any information or materials on this website is entirely at your own risk, and we shall not be liable. It is your responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
                    </li>

                    <li className="mb-2">
                      <strong>Privacy Policy</strong>
                      <br />
                      Your use of this website is also governed by our Privacy Policy, which can be found on our <a href="/privacy" target="_blank">Privacy Policy page.</a>
                    </li>

                    <li className="mb-2">
                      <strong>Intellectual Property</strong>
                      <br />
                      This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, graphics, and content. Reproduction is prohibited unless with proper acknowledgment and in accordance with copyright laws.
                    </li>

                    <li className="mb-2">
                      <strong>Third-Party Links</strong>
                      <br />
                      This website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                    </li>

                    <li className="mb-2">
                      <strong>Limitation of Liability</strong>
                      <br />
                      In no event shall Nurture with Care Kids Academy be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the website; (b) any conduct or content of any third party on the website; (c) any content obtained from the website; or (d) unauthorized access, use, or alteration of your transmissions or content.
                    </li>

                    <li className="mb-2">
                      <strong>Changes to Terms</strong>
                      <br />
                      We reserve the right to modify or replace these Terms at any time without prior notice. Your continued use of the website after any changes to the Terms constitutes acceptance of those changes.
                    </li>

                    <li className="mb-2">
                      <strong>Governing Law</strong>
                      <br />
                      These Terms shall be governed and construed in accordance with the laws of Washington State, USA, without regard to its conflict of law provisions.
                    </li>

                    <li className="mb-2">
                      <strong>Contact Us</strong>
                      <br />
                      If you have any questions about these Terms, please contact us at <a href="mailto:info@nurturewithcarekidsacademy.com">info@nurturewithcarekidsacademy.com</a>
                    </li>

                  </ol>

                </div>
              </div>

            </div>
          </div>

        </section>
      </div>
    </>

  )
}

export default Terms