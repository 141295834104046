import ScrollToTop from "../assets/js/Scrolltotop"


const Privacy = () => {

  return (
    <>
      <ScrollToTop/>
      <div id="page-section">
        <section id="blog" style={{paddingTop:"0"}}>

          <div className="container-fluid">

            <div className="jumbo-heading">
              <h1>Privacy Policy</h1>
              <small>Last updated: August 25, 2023</small>
            </div>


            <div className="container">
              <div id="blog-container" className="col-md-12">
                <div className="blog-post post-main">

                  <p>Welcome to Nurture with Care Kids Academy’s Privacy Policy. We are committed to safeguarding the privacy of our website visitors and service users. This policy outlines how we collect, use, and protect the personal information you provide to us.</p>

                  <ol>
                    <li className="mb-2">
                      <strong>Information We Collect:</strong>
                      <br />
                      <strong>Personal Information:</strong> We may collect personal information, such as names, contact details, and other relevant information, when you submit forms or interact with our website.
                      <br />
                      <strong>Usage Data:</strong> We may collect information about how you use our website, including pages visited, time spent on pages, and other analytical data.
                    </li>

                    <li className="mb-2">
                      <strong>How We Use Your Information:</strong>
                      <br />
                      <strong>Personalized Experience:</strong> We use the information collected to personalize your experience on our website and provide you with relevant content and services.
                      <br />
                      <strong>Communication:</strong> We may use your contact details to respond to your inquiries, provide information about our services, and send occasional updates.
                      <br />
                      <strong>Improvements:</strong> The data collected helps us understand how our website is used, allowing us to make improvements to content and user experience.
                    </li>

                    <li className="mb-2">
                      <strong>Cookies</strong>
                      <br />
                      We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                    </li>

                    <li className="mb-2">
                      <strong>Data Security</strong>
                      <br />
                      This website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                    </li>

                    <li className="mb-2">
                      <strong>Third-Party Links</strong>
                      <br />
                      Our website may contain links to third-party websites. We have no control over their privacy practices and are not responsible for their content or policies.
                    </li>

                    <li className="mb-2">
                      <strong>Children's Privacy</strong>
                      <br />
                      We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us to have the information removed.
                    </li>

                    <li className="mb-2">
                      <strong>Your Choices</strong>
                      <br />
                      You can choose to restrict the collection or use of your personal information by adjusting your browser settings or opting out of certain communications.
                    </li>

                    <li className="mb-2">
                      <strong>Changes to this Privacy Policy</strong>
                      <br />
                      We may update our privacy policy from time to time. Any changes will be posted on this page with a revised "Last updated" date.
                    </li>

                    <li className="mb-2">
                      <strong>Contact Us</strong>
                      <br />
                      If you have questions or concerns about this privacy policy, please contact us at <a href="mailto:info@nurturewithcarekidsacademy.com">info@nurturewithcarekidsacademy.com</a>
                    </li>

                  </ol>

                  <p><strong>By using our website, you consent to the terms of this Privacy Policy.</strong></p>

                </div>
              </div>

            </div>
          </div>

        </section>
      </div>
    </>

  )
}

export default Privacy